import React from 'react';
import Img from 'gatsby-image';

// components
import Gallery from './Gallery';

// styles
import styles from '../css/components/Showcase.module.css';

const Showcase = ({ showcase }) => {
  const name = showcase.name;
  const descriptionHtml = showcase.descriptionNode.childMarkdownRemark.html;
  const coverImage = showcase.coverImage.fluid;
  const gallery = showcase.gallery;

  return (
    <article className={styles.showcase}>
      <h2>{name}</h2>
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      <div className={styles.cover}>
        <Img fluid={coverImage} />
      </div>
      <Gallery photos={gallery} />
    </article>
  );
};

export default Showcase;
