import React from 'react';
import { graphql } from 'gatsby';

// components
import Container from '../components/Container';
import Showcase from '../components/Showcase';

export default ({ data, location }) => {
  const showcaseData = data.allDatoCmsShowcase.edges;
  const showcase = showcaseData.map(edge => <Showcase key={edge.node.id} showcase={edge.node} />);

  return (
    <Container title="Projekty" permalink={location.pathname}>
      <section className="wrap">{showcase}</section>
    </Container>
  );
};

export const query = graphql`
  query {
    allDatoCmsShowcase(filter: { locale: { eq: "cs" } }) {
      edges {
        node {
          id
          name
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          gallery {
            id
            sizes(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
              src
            }
            fluid(
              maxWidth: 100
              imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "100", h: "100" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
          coverImage {
            fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
